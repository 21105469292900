@import "general/colors";
@import "general/buttons";
@import "general/typography";
@import "contact/modalSuccess";
.contact__blue {
    .contact__title {
        @include h2;
        font-style: normal;
        text-align: center;
        color: $text;
        padding: 3em 0 2em 0;
    }
    .contact__container {
        .contact-form__field-item.error span {
            color: #eb1747;
        }
        .contact__title {
            font-family: $typography;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 146.4%;
            text-align: center;
            letter-spacing: 0.03em;
            color: $text;
            //background-color: $primary-blur;
            padding: 50px 0;
        }
        .contact-form {
            padding: 0 12px;
            //background-color: $primary-blur;
            &>form {
                display: flex;
                flex-direction: column;
                gap: 22px;
            }
        }
        .contact-form__field-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &>label {
                @include h5--strong;
            }
            &>input,
            &>textarea,
            &>select {
                @include h5;
                padding: 12px 18px;
                width: 100%;
                background: $white;
                border-radius: 25px;
                resize: none;
                border: solid 1px $blocking;
            }
            &.error {
                input,
                select,
                textarea {
                    border: solid 2px #eb1747;
                }
            }
        }
        .contact-form__button {
            &>input {
                @include h4;
                font-weight: bold;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.03em;
                background-color: $secondary;
                border-radius: 25px;
                color: $text;
                box-sizing: border-box;
                height: 41px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .contact__image {
            //background-color: $primary-blur;
            padding: 35px 20px 40px 20px;
            &>img {
                width: 100%;
            }
        }
        @media screen and (min-width: 574px) {
            .contact-form {
                padding: 0 16px;
                &>form {
                    gap: 30px;
                }
            }
            .contact-form__button {
                display: flex;
                justify-content: center;
                &>input {
                    width: 296px;
                    height: 48px;
                }
            }
            .contact__image {
                padding: 40px;
            }
        }
        @media screen and (min-width: 768px) {
            // .contact__title {
            //     padding: 40px 0 31px 0;
            // }
            .contact-form {
                padding: 0 18px;
                &>form {
                    gap: 21px;
                }
            }
            .contact-form__button {
                &>input {
                    width: 396px;
                    height: 44px;
                }
            }
            .contact__image {
                padding: 70px 140px;
                display: flex;
                justify-content: center;
                align-items: center;
                &>img {
                    width: 517px;
                }
            }
        }
        @media screen and (min-width: 1000px) {
            // .contact__title {
            //     padding: 40px 0 14px 0;
            // }
            .contact-form {
                padding: 0 62px;
            }
            .contact__image {
                padding: 31px 0 51px 0;
                &>img {
                    width: 230px;
                }
            }
        }
        @media screen and (min-width: 1400px) {
            & {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(4, 1fr);
                padding-bottom: 4em;
                //background-color: $primary-blur;
            }
            // .contact__title {
            //     @include h2;
            //     text-align: left;
            //     padding-left: 129px;
            //     padding-top: 38px;
            //     grid-column: 1 / 4;
            // }
            .contact-form {
                grid-row: 1 / 5;
                grid-column: 1 / 3;
                padding-left: 129px;
                &>form {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    gap: 40px;
                }
            }
            .contact-form__button {
                justify-content: flex-start;
                &>input {
                    width: 295px;
                    height: 48px;
                    &:hover {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ffd343;
                    }
                }
            }
            .contact__image {
                grid-column: 3 / 4;
                grid-row: 2 / 5;
                padding: 0;
                padding-bottom: 50px;
                width: 100%;
                &>img {
                    width: 327px;
                }
            }
        }
        @media screen and (min-width: 1920px) {
            & {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(4, 1fr);
            }
            // .contact__title {
            //     @include h2;
            //     text-align: left;
            //     padding-left: 123px;
            //     grid-column: 1 / 3;
            // }
            .contact-form {
                grid-row: 1 / 5;
                grid-column: 1 / 3;
                padding-left: 133px;
                &>form {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                }
            }
            .contact-form__button {
                justify-content: flex-start;
                &>input {
                    width: 295px;
                    height: 48px;
                }
            }
            .contact__image {
                grid-column: 3 / 4;
                grid-row: 1 / 5;
            }
        }
    }
}